import React, { Fragment, useContext, useState, useEffect } from "react";
import { useDrag, useDrop } from 'react-dnd';
import classnames from 'classnames';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import styles from './styles.module.scss';
import { StateContext } from "./StateProvider";

const LAG_FARGER = [
    {"tekst": "Blå", "color": "blue"},
    {"tekst": "Rød", "color": "red"},
    {"tekst": "Gul", "color": "yellow"},
    {"tekst": "Svart", "color": "black"}
];

const Player = ({ player, teamIndex, setIsDraggingPlayer }) => {
    const {
        visPosisjon,
        visGruppe,
    } = useContext(StateContext);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'PLAYER',
    item: { id: player.id, teamIndex },
    collect: (monitor) => ({
        isDragging: monitor.isDragging()
    }),
  }));

    useEffect(() => {
        setIsDraggingPlayer(isDragging);
    }, [isDragging, setIsDraggingPlayer]);
  
  return (
    <TableRow 
        ref={drag}
        style={{ opacity: isDragging ? 0.3 : 1}}
    >
        <TableCell className={styles.tableCellDrag}>
            {player.name}
        </TableCell>
        {visPosisjon &&
        <TableCell className={styles.tableCell}>
            {player.position.charAt(0)}
        </TableCell>
        }
        {visGruppe &&
        <TableCell className={styles.tableCell}>
            {player.group}
        </TableCell>
        }
    </TableRow>
  );
};

export const TeamTables = ({ teams, setTeams, updateTeams }) => {
    const [isDraggingPlayer, setIsDraggingPlayer] = useState(false);

    const deletePlayer = (playerId) => {
        const updatedTeams = teams.map((team) =>
            team.filter((player) => player.id !== playerId)
        );
        updateTeams(updatedTeams);
        setIsDraggingPlayer(false);
    };

    const movePlayer = (fromTeamIndex, toTeamIndex, playerId) => {
        setTeams(prevTeams => {
            const updatedTeams = [...prevTeams];
            const playerToMove = updatedTeams[fromTeamIndex].find(player => player.id === playerId);
    
            // Fjern spilleren fra det opprinnelige laget
            updatedTeams[fromTeamIndex] = updatedTeams[fromTeamIndex].filter(player => player.id !== playerId);
    
            // Legg til spilleren på det nye laget
            updatedTeams[toTeamIndex] = [...updatedTeams[toTeamIndex], playerToMove];
            return updatedTeams;
        });

    };

    return (
        <Fragment>
            <TrashCan deletePlayer={deletePlayer} isDraggingPlayer={isDraggingPlayer} />
            <div className={classnames(styles.tableContainer, 'teamTable')}>
                {teams.map((team, teamIndex) => (
                    <TeamTable key={teamIndex} team={team} teamIndex={teamIndex} movePlayer={movePlayer} setIsDraggingPlayer={setIsDraggingPlayer} />
                ))}
            </div>
        </Fragment>
    );
};

const TeamTable = ({ team, teamIndex, movePlayer, setIsDraggingPlayer }) => {
  
    const [{ isOver, canDrop }, dropToTeam] = useDrop(() => ({
      accept: 'PLAYER',
      hover: (draggedItem) => {
        // console.log(draggedItem);
          // if (draggedItem.id !== player.id && draggedItem.teamIndex !== teamIndex) {
          //   movePlayer(draggedItem.teamIndex, teamIndex, draggedItem.id);
          //   draggedItem.teamIndex = teamIndex;
          // }
      },
      canDrop: (item) => {
        return item.teamIndex !== teamIndex;
      },
      drop: (draggedItem) => {
        if (draggedItem.teamIndex !== teamIndex) {
            movePlayer(draggedItem.teamIndex, teamIndex, draggedItem.id);
            draggedItem.teamIndex = teamIndex;
        }
      },
      collect: (monitor) => {
        return ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        });
      }
    }));

    return (
        <div className={classnames(styles.table, { [styles.dropToTeam]: (isOver && canDrop)})} ref={dropToTeam}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            colSpan={3}
                            className={classnames(
                                styles.tableHeaderCell,
                                styles[`tableHeaderColor-${LAG_FARGER[teamIndex].color}`],
                            )}><span>{`Lag ${LAG_FARGER[teamIndex].tekst}`}</span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {team
                        .sort((a, b) => a.group?.localeCompare(b.group))
                        .map((player) => (
                            <Player
                                key={player.id}
                                player={player}
                                teamIndex={teamIndex}
                                setIsDraggingPlayer={setIsDraggingPlayer}
                            />
                        ))
                    }
                </TableBody>
            </Table>
        </div>
    );
};

const TrashCan = ({ deletePlayer, isDraggingPlayer }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'PLAYER',
    drop: (dropItem) => deletePlayer(dropItem.id),
    collect: (monitor) => {
      return ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop()
      });
    }
  });

  return (
    <div className={styles.trashCanWrapper}>
        <div
            ref={drop}
            className={classnames(
                styles.trashCan,
                {[styles.trashHover]: (isOver && canDrop)},
                {[styles.isDraggingPlayer]: isDraggingPlayer},
            )}
        >
            <span>
                Søppelbøtte
            </span>
        </div>
    </div>
  );
};